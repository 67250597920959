import * as actionTypes from './action-types';

const initialState = {
    id: '',
    title: '',
    description: '',
    state: undefined,
    location: null,
    dueDate: -1,
    createdAt: -1,
    tasks: [],
    petitionerId: '',
    petitionerOrganization: null,
    serialNumber: -1,
    populations: null,
    minVol: 0,
    maxVol: 0,
    population: null,
    completionData: null,
    numVolunteers: 0,
    beneficiaryNum: null,
    isMultipleVolunteers: false,
    imgUrl: '',
    maximumVolunteers: 0,
    groupVolunteers: [],
    whiteLabel: {
        name: '',
        id: '',
        logo: '',
        type: ''
    },
    volunteerGroupLeader: '',
    showThankYou: false,
    showTooManyVolunteering: false,
    showCanBeVolunteer: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.GET_CASE_SUCCESS: {

            const { payload } = action;

            return {
                ...state,
                ...payload
            };
        }
        case actionTypes.GET_CASE_SERIAL: {
            const {payload} = action;

            const {serialNumber} = payload;
            return {
                ...state,
                serialNumber
            }
        }
        case actionTypes.GET_CASE_GROUP_VOLUNTEERS: {

            const { payload } = action;

            return {
                ...state,
                groupVolunteers: payload.groupVolunteers
            };
        }
        case actionTypes.TOGGLE_THANK_YOU: {

            const thankYou = state.showThankYou

            return {
                ...state,
                showThankYou: !thankYou
            }
        }
        case actionTypes.TOO_MANY_VOLUNTEERING: {
            const tooManyVolunteers = state.showTooManyVolunteering

            return {
                ...state,
                showTooManyVolunteering: !tooManyVolunteers
            };
        }
        case actionTypes.CAN_BE_VOLUNTEER: {
            const canBeVolunteer = state.showCanBeVolunteer
            
            return {
                ...state,
                showCanBeVolunteer: !canBeVolunteer
            };
        }
        default:
            return state;
    }
};
