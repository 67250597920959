import React, {Component} from 'react';
import {connect} from "react-redux";
import history from '../../../utils/history';
import * as selectors from "./selectors.js";
import * as actions from './actions';
import * as homeSelectors from '../homepage/selectors';
import * as homeActions from '../homepage/actions';
import {ADD_PENDING_TO_AUTH_TASK} from "../auth/action-types";
import {CLOSE_EMAIL_VALIDATION_GENERATE_MODAL} from '../homepage/action-types';
import AuthConsumer from "../auth/auth-protected";
import {CAN_BE_VOLUNTEER, TOGGLE_THANK_YOU, TOO_MANY_VOLUNTEERING} from "./action-types";
import {SIGN_UP_CONTEXTS} from '../../../common/constants';

export default (CaseGui) => {
    const mapStateToProps = (state) => ({
        caseData: selectors.selectCaseState(state),
        showEmailModal: homeSelectors.selectEmailGenerationSuccess(state)
    });

    const mapDispatchToProps = (dispatch) => ({
        getCase: (serial) => dispatch(actions.getCase(serial)),
        getCaseSerial: (id) => dispatch(actions.getCaseSerial(id)),
        addTaskToPendingTask: (caseData, taskId) => dispatch({type: ADD_PENDING_TO_AUTH_TASK, payload: {caseData, taskId}}),
        addVolunteerToCase: (caseId, taskId, redirectUrl) => dispatch(actions.addVolunteerToCase(caseId, taskId, redirectUrl)),
        removeVolunteerFromGroupCase: (caseId, volunteerId) => dispatch(actions.removeVolunteerFromGroupCase(caseId, volunteerId)),
        copySuccessToast: () => actions.copySuccessToast(),
        toggleThankYou: () => dispatch({type: TOGGLE_THANK_YOU}),
        toggelTooManyVolunteering: () => dispatch({type: TOO_MANY_VOLUNTEERING}),
        toggleCanBeVolunteer: () => dispatch({type: CAN_BE_VOLUNTEER}),
        generateEmailToken: (userId) => dispatch(homeActions.generateEmailToken(userId)),
        closeGenerateTokenModal: () => dispatch({type: CLOSE_EMAIL_VALIDATION_GENERATE_MODAL}),
        handleVolunteerModals: () => dispatch(actions.handleVolunteerModals())
    });

    class CaseWrapper extends Component {

        componentDidMount() {
            const {serial, id} = this.props.match.params;
            if(id) {
                this.props.getCaseSerial(id);
            } else {
                this.props.getCase(serial);
            }
        }

        componentDidUpdate(prevProps) {
            const {user, match, caseData} = this.props;

            if (user !== prevProps.user ||
                match.params.companyId !== prevProps.match.params.companyId ||
                ((caseData.whiteLabel && !prevProps.caseData.whiteLabel) ||
                    (prevProps.caseData.whiteLabel && caseData.whiteLabel && caseData.whiteLabel.id !== prevProps.caseData.whiteLabel.id))) {
                const {serial} = match.params;
                this.props.getCase(serial);
            }

            // console.log(this.props);

            if(prevProps.match.params.id && prevProps.caseData.serialNumber === -1 && caseData.serialNumber !== -1) {
                const newUrl = prevProps.match.params.companyId ? `/c/${caseData.serialNumber}/gr` : `/c/${caseData.serialNumber}`;
                history.replace(newUrl)
            }
        }

        handleGenerateEmailToken = () => {
            if(!this.props.user) {
                history.navigate({
                    pathname: '/signin',
                    state: {
                        context: SIGN_UP_CONTEXTS.GENERATE_EMAIL
                    }
                })
            } else {
                this.props.generateEmailToken(this.props.user.id);
            }
        }

        render() {
            const isCurrentUserVolunteers = this.props.user && this.props.caseData.tasks[0] && this.props.caseData.tasks[0].groupVolunteers ? this.props.caseData.tasks[0].groupVolunteers.includes(this.props.user.id) : false;
            return (
                <CaseGui
                    caseData={this.props.caseData}
                    addTaskToPendingTask={this.props.addTaskToPendingTask}
                    removeVolunteerFromGroupCase={this.props.removeVolunteerFromGroupCase}
                    addVolunteerToCase={this.props.addVolunteerToCase}
                    currentUser={this.props.user}
                    isCurrentUserVolunteers={isCurrentUserVolunteers}
                    copySuccessToast={this.props.copySuccessToast}
                    toggleThankYou={this.props.toggleThankYou}
                    toggelTooManyVolunteering={this.props.toggelTooManyVolunteering}
                    showEmailModal={this.props.showEmailModal}
                    generateEmailToken={this.handleGenerateEmailToken}
                    closeGenerateTokenModal={this.props.closeGenerateTokenModal}
                    handleVolunteerModals={this.props.handleVolunteerModals}
                    toggleCanBeVolunteer={this.props.toggleCanBeVolunteer}
                />
            );
        }
    }

    return AuthConsumer(connect(mapStateToProps, mapDispatchToProps)(CaseWrapper));
};
